import GlobalEventBus from '@services/GlobalEventBus'

export default {
  spawn (componentName, params) {
    GlobalEventBus.dispatch('spawn-modal', { componentName, params })
  },

  close (modalUuid) {
    GlobalEventBus.dispatch('destroy-modal', modalUuid)
  },
}
