import BaseModel from '@models/Base'
import UserModel from '@models/User'

export default class TechnicianStatusReport extends BaseModel {
  constructor (initialData) {
    super(initialData, {
      columnNames: [
        'id',
        'report_type',
        'morning_rating',
        'morning_rating_text',
        'morning_office_alert',
        'afternoon_rating',
        'afternoon_rating_text',
        'afternoon_office_alert',
        'technician_id',
        'metadata',
        'recorded_at',
        'created_at',
        'updated_at',
      ],
      dateKeys: [
        'recorded_at',
        'created_at',
        'updated_at',
      ],
      relationshipMap: {
        technician: {
          model: UserModel,
        },
      },
    })
  }

  get isMorningReport () {
    return this.report_type === 'morning'
  }

  get isAfternoonReport () {
    return this.report_type === 'afternoon'
  }

  get rating () {
    if (this.isMorningReport) {
      return this.morning_rating
    } else if (this.isAfternoonReport) {
      return this.afternoon_rating
    } else {
      return null
    }
  }

  get ratingText () {
    if (this.isMorningReport) {
      return this.morning_rating_text
    } else if (this.isAfternoonReport) {
      return this.afternoon_rating_text
    } else {
      return null
    }
  }

  get officeAlert () {
    if (this.isMorningReport) {
      return this.morning_office_alert
    } else if (this.isAfternoonReport) {
      return this.afternoon_office_alert
    } else {
      return null
    }
  }

  get hasOfficeAlert () {
    return !!this.officeAlert
  }
}