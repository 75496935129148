import Vue from 'vue'
Vue.config.devtools = true
import vmodal from 'vue-js-modal'

import ModalsApp from '@components/ModalsApp.vue'

Vue.use(vmodal)

import TurbolinksAdapter from 'vue-turbolinks';
Vue.use(TurbolinksAdapter)

document.addEventListener('turbolinks:load', () => {
  if (document.querySelectorAll('#modals-vue-target').length > 0) {
    new Vue({
      el: '#modals-vue-target',
      render: h => h(ModalsApp),
    })
  }
})
