import Vue from 'vue'

const BUS = new Vue()

export default {
  subscribe (evt, cb) {
    BUS.$on(evt, cb)
    return () => BUS.$off(evt, cb)
  },

  dispatch (evt, params) {
    return BUS.$emit(evt, params)
  },
}
