<template>
  <div class="technician-status-report">
    <template v-if="mode === 'card'">
      <div class="bg-white shadow overflow-hidden sm:rounded-lg">
        <div class="px-4 py-5 sm:px-6">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Technician Status Report
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            Report for the {{ modelData.report_type }} of {{ modelData.recorded_at.toLocaleString({ weekday: 'short', month: 'short', day: 'numeric' }) }}
          </p>
        </div>
        <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
            <div class="sm:col-span-1">
              <dt class="text-sm font-medium text-gray-500">Technician</dt>
              <dd class="mt-1 text-sm text-gray-900">{{ modelData.technician.fullname }}</dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-medium text-gray-500">Report Recorded At</dt>
              <dd class="mt-1 text-sm text-gray-900">{{ fullRecordedAtDateTime }}</dd>
            </div>
            <div class="sm:col-span-1">
              <dt class="text-sm font-medium text-gray-500">
                Rating
              </dt>
              <dd class="mt-1 text-sm text-gray-900">
                <template v-for="star in [0, 1, 2, 3, 4]">
                  <svg v-if="star < modelData.rating" :key="star" class="inline-block h-7 w-7" style="color:#ffe100;" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path></svg>
                  <svg v-else :key="star" class="inline-block h-7 w-7" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="star" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM405.8 317.9l27.8 162L288 403.5 142.5 480l27.8-162L52.5 203.1l162.7-23.6L288 32l72.8 147.5 162.7 23.6-117.7 114.8z"></path></svg>
                </template>
              </dd>
            </div>
            <div class="sm:col-span-2">
              <dt class="text-sm font-medium text-gray-500">
                Rating Description
              </dt>
              <dd class="mt-1 text-sm text-gray-900">
                {{ modelData.ratingText }}
              </dd>
            </div>
            <div class="sm:col-span-3">
              <dt class="text-sm font-medium text-gray-500">
                Office Alert
              </dt>
              <dd class="mt-1 text-sm text-gray-900">
                <template v-if="modelData.hasOfficeAlert">{{ modelData.officeAlert }}</template>
                <template v-else><p class="text-gray-400">No office alerts</p></template>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </template>
    <template v-if="mode === 'row'">
      <div class="h-20 border-t border-gray-200 px-4 py-4 lg:px-6" :class="viewAlert ? 'bg-gray-100': 'bg-white'">
        <template v-if="!viewAlert">
          <dl class="grid grid-cols-1 gap-x-4 gap-y-8 lg:grid-cols-10 grid-cols-4">
            <div class="lg:col-span-1 my-auto col-span-1">
              <dd class="text-base sm:text-lg text-gray-900">{{ recordedAtDate }}</dd>
              <dd class="text-sm sm:text-base text-gray-500 -mt-1">{{ reportType }}</dd>
            </div>
            <div class="lg:col-span-1 my-auto col-span-1">
              <dt class="text-sm lg:text-base text-gray-900">{{ modelData.technician.fullname }}</dt>
              <dd class="text-xs lg:text-sm font-medium text-gray-500">at {{ recordedAtTime }}</dd>
            </div>
            <div class="lg:col-span-2 my-auto col-span-1">
              <dd class="text-sm text-gray-900 flex flex-wrap justify-center">
                <template v-for="star in [0, 1, 2, 3, 4]">
                  <svg v-if="star < modelData.rating" :key="star" class="inline-block lg:h-7 lg:w-7 h-6 w-6" style="color:#ffe100;" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path></svg>
                  <svg v-else :key="star" class="inline-block lg:h-7 lg:w-7 h-6 w-6" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="star" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM405.8 317.9l27.8 162L288 403.5 142.5 480l27.8-162L52.5 203.1l162.7-23.6L288 32l72.8 147.5 162.7 23.6-117.7 114.8z"></path></svg>
                </template>
              </dd>
            </div>
            <div class="lg:col-span-5 my-auto lg:inline-flex hidden">
              <dd class="text-sm text-gray-900">
                {{ modelData.ratingText }}
              </dd>
            </div>
            <div class="lg:col-span-1 my-auto text-right col-span-1">
              <p v-if="modelData.hasOfficeAlert" @click="viewAlert = true" class="text-indigo-600 hover:text-indigo-900 cursor-pointer lg:inline-flex hidden">View Alert</p>
              <OpenModalButton class="text-indigo-600 hover:text-indigo-900 cursor-pointer inline-flex lg:hidden" modalName="technician-status-report-modal" text="View" :params="{ data: modelData }" />
            </div>
          </dl>
        </template>
        <template v-else>
          <div class="grid grid-cols-1 gap-x-4 gap-y-8 lg:grid-cols-10 sm:grid-cols-8 text-base">
            <div class="lg:col-span-9 my-auto sm:col-span-7">
              <dt class="text-sm font-medium text-gray-500">Office Alert from {{ modelData.technician.fullname }}</dt>
              <dd class="text-sm text-gray-900">{{ modelData.officeAlert }}</dd>
            </div>
            <div class="lg:col-span-1 my-auto sm:col-span-1 text-right">
              <p @click="viewAlert = false" class="text-indigo-600 hover:text-indigo-900 cursor-pointer lg:inline-flex hidden">Back</p>
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import OpenModalButton from '@components/OpenModalButton.vue'
import TechnicianStatusReport from '@models/TechnicianStatusReport'

export default {
  name: 'technician-status-report',
  components: {
    OpenModalButton,
  },
  props: {
    mode: {
      type: String,
      default: 'card',
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data () {
    return { viewAlert: false }
  },
  computed: {
    modelData () {
      return this.data.isProcessedModel ? this.data : new TechnicianStatusReport(this.data)
    },
    fullRecordedAtDateTime () {
      return this.modelData.recorded_at.toLocaleString({ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: '2-digit' })
    },
    recordedAtDate () {
      return this.modelData.recorded_at.toLocaleString({ month: 'short', day: 'numeric' })
    },
    recordedAtTime () {
      return this.modelData.recorded_at.toLocaleString({ hour: 'numeric', minute: '2-digit' })
    },
    reportType () {
      return this.modelData.report_type.charAt(0).toUpperCase() + this.modelData.report_type.slice(1)
    },
  },
}
</script>
