<template>
  <modal class="technician-status-report-modal-container"
    :name="modalName"
    :min-height="600"
    height="auto"
    :scrollable="false"
    :adaptive="true"
    @closed="destroyModal">
    <TechnicianStatusReport :data="params.data" />
  </modal>
</template>

<script>
import TechnicianStatusReport from '@components/TechnicianStatusReport.vue'
import ModalMixin from '@mixins/ModalMixin.vue'

const NAME = 'technician-status-report-modal'

export default {
  name: NAME,
  mixins: [ModalMixin],
  components: {
    TechnicianStatusReport,
  },
  data () {
    return {
      modalName: NAME,
    }
  },
  methods: {
    close () {
      this.$modal.hide(this.modalName)
    },
  },
}
</script>

<style lang="scss">
</style>
