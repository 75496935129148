<script>
  import ModalService from '@services/ModalService'

  export default {
    methods: {
      $show () {
        this.$modal.show(this.modalName)
      },
      $hide () {
        this.$modal.hide(this.modalName)
      },
      destroyModal () {
        ModalService.close(this.modalId)
      },
    },
    props: {
      params: {
        type: Object,
        required: true,
      },
      modalId: {
        type: String,
        required: true,
      },
    },
  }
</script>
