<template>
  <div class="modals-container">
    <component
      :is="modal.component"
      v-for="modal in modalInstances"
      :key="modal.uuid"
      :ref="modal.uuid"
      :modalId="modal.uuid"
      :params="modal.params"
    />
  </div>
</template>

<script>
  import { v4 as uuid } from 'uuid'
  import GlobalEventBus from '@services/GlobalEventBus'

  /**
   * Any modal that is made within the confines of this Vue app must
   * import the ModalMixin and have the following event defined in the modal
   * tag.
   * 
   * <modal ... @closed="destroyModal"> ... </modal>
   */

  // Import all possible modal components in this section.
  import TechnicianStatusReportModal from '@modals/TechnicianStatusReportModal.vue'

  export default {
    name: 'modals-app',
    components: {
      TechnicianStatusReportModal,
    },
    data () {
      return {
        modalInstances: [],
      }
    },
    methods: {
      spawnModal (modal) {
        const u = uuid()
        if (!modal.params) {
          modal.params = {}
        }
        console.log('sd;alskd;lasd', modal)
        this.modalInstances.push({
          uuid: u,
          component: modal.componentName,
          params: modal.params || {},
        })
        const intrvl = setInterval(() => {
          if (this.$refs[u] && this.$refs[u][0]) {
            this.$nextTick(() => {
              console.log('m', this.$refs, this.$refs[u], u, this.$refs[u][0])
              this.$refs[u][0].$show()
            })
            clearInterval(intrvl)
          }
        }, 50)
      },
      destroyModal (modalUuid) {
        this.modalInstances.splice(this.modalInstances.findIndex(m => m.uuid === modalUuid), 1)
      },
    },
    created () {
      console.log('subscribing to modal events')
      GlobalEventBus.subscribe('spawn-modal', this.spawnModal)
      GlobalEventBus.subscribe('destroy-modal', this.destroyModal)
    },
  }
</script>

<style lang="scss" scoped>
  .modals-container {
    display: block;
  }
</style>